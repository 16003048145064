<template>
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="fullPage"></loading>
    <form data-vv-scope="importacaoDeParaForm" method="post">
      <div class="row justify-content-md-center p-3">
        <div class="col-12 col-md-12">
          <!-- De -->
          <div class="card">
            <div class="card-body">
              <div class="row">
                <!-- Tipo -->
                <div class="col-lg-12 col-md-12 form-group px-2">
                  <label class="small grey-label">{{  $t('form.dePara.tipo')  }}</label>
                  <v-select class="grey-field" :multiple="false" name="formData.tipo" label="name" :clearable="false" :searchable="false"
                            v-model="formData.tipo" :options="tipoList"
                            v-validate="{ required: true }"
                  >
                    <template slot="no-options" slot-scope="{searching, search }">
                      <em v-if="!searching" style="opacity: 0.5;">
                        Digite para buscar
                      </em>
                      <em v-else style="opacity: 0.5;">
                        Nenhuma opção encontrada para {{ search }}
                      </em>
                    </template>
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        {{ option.name }}
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{ option.name }}
                      </div>
                    </template>
                  </v-select>
                  <transition name="slide" mode="">
                    <small v-if="errors.first('formData.campo')" class="royalc-error-field">{{ errors.first('formData.campo') }}</small>
                  </transition>
                </div>

                <!-- Campo -->
                <div class="col-lg-12 col-md-12 form-group px-2">
                  <label class="small grey-label">{{  $t('form.dePara.campo')  }}</label>
                  <v-select class="grey-field" :multiple="false" name="formData.campo" label="name" :clearable="false" :searchable="false"
                            v-model="formData.campo" :options="campoList"
                            v-validate="{ required: true }"
                  >
                    <template slot="no-options" slot-scope="{searching, search }">
                      <em v-if="!searching" style="opacity: 0.5;">
                        Digite para buscar
                      </em>
                      <em v-else style="opacity: 0.5;">
                        Nenhuma opção encontrada para {{ search }}
                      </em>
                    </template>
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        {{ option.name }}
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{ option.name }}
                      </div>
                    </template>
                  </v-select>
                  <transition name="slide" mode="">
                    <small v-if="errors.first('formData.campo')" class="royalc-error-field">{{ errors.first('formData.campo') }}</small>
                  </transition>
                </div>

                <!-- De -->
                <div class="col-lg-12 col-md-12 form-group px-2">
                  <custom-input
                    v-model="formData.de"
                    :label="$t('form.dePara.de')"
                    name="formData.de"
                    type="text"
                    v-validate="{ required: true }"
                    :error="(submitted) ? errors.first('formData.de') : ''">
                  </custom-input>
                </div>

                <!-- Para -->
                <div class="col-lg-12 col-md-12 form-group px-2">
                  <custom-input
                    v-model="formData.para"
                    :label="$t('form.dePara.para')"
                    name="formData.para"
                    type="text"
                    v-validate="{ required: true }"
                    :error="(submitted) ? errors.first('formData.para') : ''">
                  </custom-input>
                </div>
              </div>
            </div>
          </div>

        </div>

        <!-- Submit -->
        <div class="col-12">
          <button type="button" class="btn btn btn-outline-light pull-right" @click="onSave">Salvar</button>
          <button type="button" class="btn btn-link btn-sm mt-1 pull-right" @click="onCancel">Cancelar</button>
        </div>

      </div>
    </form>
  </div>
</template>

<script>
// Components
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import CustomInput from '@/components/Forms/CustomInput.vue'

// eslint-disable-next-line
import { util } from '@/utils/utils.js'

// Form
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

// eslint-disable-next-line
import '@/assets/vendor/reflow-table/js/reflow-table.js'
import '@/assets/vendor/reflow-table/css/reflow-table.css'

// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js'
// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/locales/bootstrap-datepicker.pt-BR.min.js'
import '@/assets/vendor/bootstrap-datepicker/dist/css/bootstrap-datepicker3.min.css'

// Services
import ImportacaoDeParaService from '@/services/ImportacaoDeParaService'

export default {
  name: 'importacaoDeParaForm',
  metaInfo () {
    return {
      titleTemplate: this.$i18n.t('index.import.title') + ' - %s'
    }
  },
  data () {
    return {
      originDestinationList: [],
      campoList: [
        {
          id: 1,
          name: 'Origem/Destino'
        },
        {
          id: 2,
          name: 'Armador'
        }
      ],
      tipoList: [
        {
          id: 1,
          name: 'Log Comex'
        }
      ],
      formData: {
        tipo: {
          id: 1,
          name: 'Log Comex'
        },
        campo: {
          id: 2,
          name: 'Armador'
        },
        de: null,
        para: null
      },
      formErrors: {},
      isLoading: true,
      fullPage: true,
      // List of options to select on select input
      submitted: false
    }
  },
  components: {
    Loading,
    vSelect,
    CustomInput
  },
  // Apply filters to local filter
  beforeMount () {

  },
  mounted () {
    // this.$store.dispatch('SetShowFilter', true)
    // this.$store.dispatch('setShowSearch', false)
  },
  created () {
    let _this = this
    _this.isLoading = true

    // If is edit populate values
    let id = this.$route.params.id

    if (id) {
      ImportacaoDeParaService.get(id).then((res) => {
        _this.formData = res.data.data
        _this.isLoading = false
      })
    } else {
      _this.isLoading = false
    }
  },
  methods: {
    onCancel (e) {
      var _this = this
      _this.$router.push({ name: 'IndexDeParaIndex' })
    },
    displayErrors (errors) {
      let formatedErrors = util.getFormErrors(errors)
      this.formErrors = formatedErrors
    },
    onSave (e) {
      var _this = this
      this.submitted = true
      _this.$validator.validateAll('importacaoDeParaForm').then(valid => {
        if (valid) {
          _this.isLoading = true

          let data = Object.assign({}, _this.formData)

          data.campo = data.campo.id
          data.tipo = data.tipo.id

          let id = this.$route.params.id
          if (id) {
            ImportacaoDeParaService.put(id, data).then(response => {
              _this.$router.push({ name: 'IndexDeParaIndex' })
            }).catch(err => {
              if (err.code === 400) {
                _this.displayErrors(err.errors)
              }
              _this.isLoading = false
            })
          } else {
            ImportacaoDeParaService.post(data).then(response => {
              _this.$router.push({ name: 'IndexDeParaIndex' })
            }).finally(() => {
              _this.isLoading = false
            })
          }
        }
      })
    }
  }
}
</script>

<style scoped>
  .card-body {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
</style>
